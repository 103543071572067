import React, { useState, useEffect } from "react";
import "../CSS/main.css";
import ChartInfo from "./chartData";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const loginToken = await localStorage.getItem("loginToken");
    if (!loginToken) {
      navigate("/");
    }
    try {
      const res = await axios.get(
        `https://api.sellercircle.co.uk/api/v1/users/current`,
        {
          headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
          },
        }
      );
      const { data } = await axios.get(
        `https://api.sellercircle.co.uk/api/v1/users/${res?.data?.id}/products`,
        {
          headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
          },
        }
      );
      console.log(data, "res");
      await localStorage.setItem("hook_id", data[0]?.hook?.id);
      setProducts(data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="dashboardChart_container">
      <h4>Dashboard</h4>
      <div className="chart_title" style={{}}>
        <h5 style={{ flex: 1, flexShrink: 0, marginRight: 0 }}>Product Name</h5>
        <h5 style={{ flex: 1, marginLeft: 0, marginRight: 0, flexShrink: 0 }}>
          Website
        </h5>
        <h5 style={{ flex: 1, marginLeft: 0, marginRight: 0, flexShrink: 0 }}>
          Hook
        </h5>
        <h5 style={{ flex: 3, marginLeft: 0, marginRight: 0, flexShrink: 0 }}>
          URL
        </h5>
      </div>
      <div className="dashboardchart_main">
        {products?.map((product, i) => (
          <ChartInfo key={i} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
