import React, { useState } from "react";
import "../CSS/main.css";
import { useNavigate } from "react-router-dom";
import InputTrendy from "./InputTrendy";
import axios from "axios";

import MainImage from "../assets/main.png";
import backImage from "../assets/back.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);

  const goToSignInHandler = () => {
    navigate("/");
  };

  const onSend = async () => {
    if (!email) {
      window.alert("Please type the email address");
      return false;
    }

    const valid =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      );
    if (!valid) {
      window.alert("Please type the valid email address");
      return false;
    }
    const config = {
      "Content-Type": "application/json",
    };
    const payload = {
      email,
    };
    const { data } = await axios.post(
      "https://api.sellercircle.co.uk/api/v1/rest-auth/password/reset/",
      payload,
      config
    );
    window.alert(data.detail);
  };

  return (
    <div className="main_container">
      <div className="signLeft_container">
        <div className="back_container trendy_forgotPassword">
          <img src={backImage} onClick={() => goToSignInHandler()} />
        </div>
        <h2>Trendy</h2>
        <p>Forgot your password? Reset it now</p>
        <div className="input_container">
          <InputTrendy
            id="email"
            name="email"
            type="text"
            label="Email Address"
            placeholder="Beta@trendy.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="signUpButton_container">
            <button onClick={onSend} className="signIn_button signUp_setting">
              Send Email Reset
            </button>
          </div>
        </div>
      </div>
      <div className="signRight_container">
        <img src={MainImage} />
      </div>
    </div>
  );
};

export default ForgotPassword;
