import React from "react";
import axios from "axios";
import binImage from "../assets/bin.png";
import externalImage from "../assets/external.png";

const ChartInfo = ({ product }) => {
  const onRemove = async () => {
    if (window.confirm("Are you sure to delete this product?")) {
      const loginToken = await localStorage.getItem("loginToken");
      try {
        const res = await axios.get(
          `https://api.sellercircle.co.uk/api/v1/users/current`,
          {
            headers: {
              Authorization: "Token " + loginToken,
              Accept: "application/json",
            },
          }
        );
        console.log(res.data);
        const { data } = await axios.delete(
          `https://api.sellercircle.co.uk/api/v1/users/${res?.data?.id}/products/${product?.id}/delete/`,
          {
            headers: {
              Authorization: "Token " + loginToken,
              Accept: "application/json",
            },
          }
        );
        console.log(data, "res");
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <div className="chartInfo_main" style={{ display: "flex" }}>
      <p
        className="chartInfo_mainName"
        style={{ flex: 1, width: "auto", flexShrink: 0 }}
      >
        {product?.name}
      </p>
      <p
        className="chartInfo_mainWebsite"
        style={{ flex: 1, width: "auto", flexShrink: 0 }}
      >
        {product?.website?.name}
      </p>
      <p
        className="chartInfo_mainWebsite"
        style={{ flex: 1, width: "auto", flexShrink: 0 }}
      >
        {product?.hook?.name}
      </p>
      <div
        className="chartInfo_mainUrl"
        style={{
          flex: 3,
          width: "auto",
          flexShrink: 0,
          overflow: "hidden",
          paddingRight: 20,
        }}
      >
        <p className="mainUrl_detail" style={{ width: "auto" }}>
          {product?.url}
        </p>
        <img
          onClick={() => window.open(product?.url, "_blank")}
          src={externalImage}
        />
        <img onClick={() => onRemove()} src={binImage} />
      </div>
    </div>
  );
};

export default ChartInfo;
