import React, { useEffect, useState } from "react";
import "../CSS/main.css";
import { useNavigate } from "react-router-dom";

import MainImage from "../assets/main.png";
import InputTrendy from "./InputTrendy";
import axios from "axios";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") !== "" &&
      localStorage.getItem("loginToken") !== null
    ) {
      navigate("/main/dashboard");
    }
    console.log(localStorage.getItem("loginToken"));
  }, []);

  const loginAction = async (e) => {
    setValidationErrors(null);
    e.preventDefault();
    setIsSubmitting(true);
    let loginInfo = {
      email: email,
      password: password,
    };

    await axios
      .post("https://api.sellercircle.co.uk/api/v1/rest-auth/login/", loginInfo)
      .then((e) => {
        console.log(e.data, "e.data");
        setIsSubmitting(false);
        localStorage.setItem("loginToken", e.data.key);
        navigate("/main/dashboard");
      })
      .catch((e) => {
        console.log(e.response.data, "-------------");
        setIsSubmitting(false);
        if (e.response.status === 400) {
          setValidationErrors(Object.values(["Invalid Password or Email Address",]));
        } else {
          setValidationErrors(Object.values(e.response.data));
        }
        
        // if (e.response.data.errors !== undefined) {
        //   setValidationErrors(true);
        //   console.log("errors", setValidationErrors(e.response.data.errors))
        // }
        // if (e.response.data.error !== undefined) {
        //   setValidationErrors(true);
        //   console.log("error", setValidationErrors(e.response.data.error))
        // }
      });
  };

  const goToSignUpHandler = () => {
    navigate("/signUp");
  };

  const goToForgotPassHandler = () => {
    navigate("/forgotPassword");
  };

  return (
    <div className="main_container">
      <div className="signLeft_container signIn_margin">
        <h2>Trendy</h2>
        <p>Welcome back, please login to your account</p>
        <div className="input_container">
          <form onSubmit={(e) => loginAction(e)}>
            <InputTrendy
              id="email"
              name="email"
              type="text"
              label="Email Address"
              placeholder="Beta@trendy.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <InputTrendy
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="**********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {validationErrors && (
              <p>
                <small style={{ color: "red" }}>{validationErrors}</small>
              </p>
            )}

            <div
              className="forgot_password"
              onClick={() => goToForgotPassHandler()}
            >
              Forgot password?
            </div>
            <div className="loginButton_container">
              <button
                className="signIn_button"
                disabled={isSubmitting}
                type="submit"
              >
                Sign In
              </button>
              <button
                className="signUp_button"
                onClick={() => goToSignUpHandler()}
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="signRight_container">
        <img src={MainImage} />
      </div>
    </div>
  );
};

export default SignIn;
