import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CSS/main.css";
import { useNavigate } from "react-router-dom";

import MainImage from "../assets/main.png";
import InputTrendy from "./InputTrendy";
import backImage from "../assets/back.png";

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passWord1, setPassWord1] = useState("");
  const [passWord2, setPassWord2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);

  const goToSignInHandler = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   if(localStorage.getItem('token') !== "" && localStorage.getItem('token') !== null) {
  //   }
  // }, [])
  const config = {
    headers: {
      "Content-type": "application/json",
      // Authorization: 'Token ' + csrftoken
    },
  };

  const registerAction = async (e) => {
    console.log("-=-=-=--=");
    setMessage(null);
    e.preventDefault();
    setIsSubmitting(true);
    let payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      password1: passWord1,
      password2: passWord2,
    };

    if (passWord1 !== passWord2) {
      setMessage("Confirm password is not matched!");
      return false;
    }

    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*?.])[a-zA-Z0-9!@#$%^&*?.]{8,42}$/;

    const valid = regularExpression.test(passWord1);
    if (!valid) {
      setMessage(
          "Your password must be a minimum of 8 characters and contain at least one number and one special character from the following options: ! @ # $ % ^ & . ? *"
      );
      return false;
    }

    await axios
      .post(
        "https://api.sellercircle.co.uk/api/v1/rest-auth/registration/",
        payload,
        config
      )
      .then((e) => {
        console.log(e, "e");
        setIsSubmitting(false);
        window.alert("You will receive an email to verify your account");
        // localStorage.setItem('loginToken', e.data.key)
        // console.log('dadafaf', r.data)
        navigate("/");
      })
      .catch((e) => {
        console.log(e, "e");
        setMessage(Object.values(e.response.data));
        setIsSubmitting(false);
      });
  };

  return (
    <div className="main_container">
      <div className="signLeft_container" style={{ overflow: "scroll" }}>
        <div className="back_container">
          <img src={backImage} onClick={() => goToSignInHandler()} />
        </div>
        <h2>Trendy</h2>
        <p>Create your account now</p>
        <div className="input_container">
          <form onSubmit={(e) => registerAction(e)}>
            {/* <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)}/> */}
            <InputTrendy
              id="firstname"
              name="firstname"
              type="text"
              label="First Name"
              placeholder="Beta"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <InputTrendy
              id="lastname"
              name="lastname"
              type="text"
              label="Last Name"
              placeholder="Trendy"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <InputTrendy
              id="email"
              name="email"
              type="text"
              label="Email"
              placeholder="Beta@Trendy.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputTrendy
              id="telnumber"
              name="telnumber"
              type="tel"
              label="Phone Number"
              placeholder=""
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e);
                console.log(e);
              }}
            />
            <InputTrendy
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="**********"
              value={passWord1}
              onChange={(e) => setPassWord1(e.target.value)}
            />
            <InputTrendy
              id="confirmpassword"
              name="confirmpassword"
              type="password"
              label="Confirm Password"
              placeholder="**********"
              value={passWord2}
              onChange={(e) => setPassWord2(e.target.value)}
            />
            {message && (
              <p>
                <small style={{ color: "red" }}>{message}</small>
              </p>
            )}
            <div
              className="signUpButton_container"
              style={{ marginBottom: 60 }}
            >
              <button className="signIn_button signUp_setting" type="submit">
                SignUp
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="signRight_container">
        <img src={MainImage} />
      </div>
    </div>
  );
};

export default SignUp;
