import React, { useEffect, useState } from "react";
import "../CSS/main.css";
import { useNavigate, useParams } from "react-router-dom";
import InputTrendy from "./InputTrendy";
import axios from "axios";

import MainImage from "../assets/main.png";
import backImage from "../assets/back.png";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const params = useParams()
  const goToSignInHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    console.log(params, 'params')
  }, [])

  const onSend = async () => {
    if (!password) {
      window.alert("Please type new password");
      return false;
    }
    if (!confirmPassword) {
      window.alert("Please type confirm password");
      return false;
    }
    if (password !== confirmPassword) {
      window.alert("Confirm password is not matched!");
      return false;
    }
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*?.])[a-zA-Z0-9!@#$%^&*?.]{8,42}$/;

    const valid = regularExpression.test(password);
    if (!valid) {
      window.alert(
        "Your password must be a minimum of 8 characters and contain at least one number and one special character from the following options: ! @ # $ % ^ & . ? *"
      );
      return false;
    }
    const config = {
      "Content-Type": "application/json",
    };
    const payload = {
      new_password1: password,
      new_password2: confirmPassword,
      uid: params.id,
      token: params?.code
    };
    const { data } = await axios.post(
      `https://api.sellercircle.co.uk/rest-auth/password/reset/confirm/${params?.id}/${params?.code}`,
      payload,
      config
    );
    window.alert(data.detail);
    navigate('/')
  };

  return (
    <div className="main_container">
      <div className="signLeft_container">
        <div className="back_container trendy_forgotPassword">
          <img src={backImage} onClick={() => goToSignInHandler()} />
        </div>
        <h2>Trendy</h2>
        <p>Enter your new password</p>
        <div className="input_container">
          <InputTrendy
            id="new-password"
            name="new-password"
            type="password"
            label="New Password"
            placeholder="**********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputTrendy
            id="confirm-password"
            name="confirm-password"
            type="password"
            label="Confirm Password"
            placeholder="**********"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="signUpButton_container">
            <button onClick={onSend} className="signIn_button signUp_setting">
              Reset Password
            </button>
          </div>
        </div>
      </div>
      <div className="signRight_container">
        <img src={MainImage} />
      </div>
    </div>
  );
};

export default ResetPassword;
