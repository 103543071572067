import React, { useEffect, useState } from "react";
import "../CSS/main.css";
import axios from "axios";

import backImage from "../assets/back.png";
import plusImage from "../assets/plusWhite.png";

const NewModal = ({ setIsOpen }) => {
  const [name, setName] = useState(null);
  const [website, setWebsite] = useState(null);
  const [url, setUrl] = useState(null);
  const [hook, setHook] = useState(null);
  const [websites, setWebsites] = useState([]);
  const [hooks, setHooks] = useState([]);
  useEffect(() => {
    onInitialize();
  }, []);
  const onInitialize = async () => {
    const loginToken = await localStorage.getItem("loginToken");
    const response = await axios.get(
      `https://api.sellercircle.co.uk/api/v1/users/current`,
      {
        headers: {
          Authorization: "Token " + loginToken,
          Accept: "application/json",
        },
      }
    );
    const res = await axios.get(
      `https://api.sellercircle.co.uk/api/v1/available-sites`,
      {
        headers: {
          Authorization: "Token " + loginToken,
          Accept: "application/json",
        },
      }
    );
    setWebsites(res.data);
    const res1 = await axios.get(
      `https://api.sellercircle.co.uk/api/v1/users/${response?.data?.id}/hooks`,
      {
        headers: {
          Authorization: "Token " + loginToken,
          Accept: "application/json",
        },
      }
    );
    console.log(res1.data, "data");
    setHooks(res1?.data?.filter((d) => d?.author === response?.data?.id));
  };
  const onSave = async () => {
    const loginToken = await localStorage.getItem("loginToken");
    console.log(name, website, url, hook, "hook");
    if (!name) {
      window.alert("Please input the product name!");
      return false;
    }
    if (!website) {
      window.alert("Please select the website!");
      return false;
    }
    if (!url) {
      window.alert("Please input the url!");
      return false;
    }
    if (!hook) {
      window.alert("Please select the hook!");
      return false;
    }
    var valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    console.log(valid, "valid");
    if (!valid) {
      window.alert("Please input the valid url");
      return false;
    }
    try {
      const res = await axios.get(
        `https://api.sellercircle.co.uk/api/v1/users/current`,
        {
          headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
          },
        }
      );
      console.log(res.data);
      const { data } = await axios.post(
        `https://api.sellercircle.co.uk/api/v1/users/${res?.data?.id}/products/add/`,
        {
          name,
          website: parseInt(website),
          url,
          hook: parseInt(hook),
        },
        {
          headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
          },
        }
      );
      console.log(data, "res");
      window.location.reload();
    } catch (e) {
      window.alert(e.response.data);
    }
  };
  return (
    <>
      <div className="modal_background" onClick={() => setIsOpen(false)} />
      <div className="signUp_container" style={{ height: 750 }}>
        <div className="signUpContainer_main" style={{ width: "590px" }}>
          <div style={{ width: "100%" }}>
            <img
              src={backImage}
              onClick={() => setIsOpen(false)}
              className="backImage_container"
            />
            <div className="dashboardChart_container">
              <h4>New Monitor</h4>
              <div className="chart_title">
                <h5>Product Name</h5>
              </div>
              <input
                placeholder="Product name..."
                onChange={(e) => setName(e?.target?.value)}
              />
              <div className="chart_title">
                <h5>Website</h5>
              </div>
              <select onChange={(e) => setWebsite(e?.target?.value)}>
                <option>Pick Website...</option>
                {websites?.map((web, i) => (
                  <option key={i} value={web?.id}>
                    {web?.name}
                  </option>
                ))}
              </select>
              <div className="chart_title">
                <h5>URL</h5>
              </div>
              <input
                placeholder="Product URL..."
                onChange={(e) => setUrl(e?.target?.value)}
              />
              <div className="chart_title">
                <h5>Hook</h5>
              </div>
              <select onChange={(e) => setHook(e?.target?.value)}>
                <option>Hook...</option>
                {hooks?.map((hook, i) => (
                  <option key={i} value={hook?.id}>
                    {hook?.name}
                  </option>
                ))}
              </select>
              <button onClick={onSave}>
                <img src={plusImage} />
                Add Monitor
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewModal;
