import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import NewModal from "./NewModal";
import "../CSS/main.css";
import axios from "axios";

import dashboardImage from "../assets/dashboard.png";
import plusImage from "../assets/plus.png";
import settingImage from "../assets/setting.png";
import guideImage from "../assets/guide.png";
import discordImage from "../assets/discord.png"
import Loader from "../assets/loader.svg";
import backImage from "../assets/back.png";

const Main = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currenctPage, setCurrentPage] = useState(null);
  const [sideToggle, setSideToggle] = useState(false);
  const [name, setName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const setSideHamburgerToggle = () => {
    setSideToggle(!sideToggle);
  };

  useEffect(() => {
    if (window.location.pathname === "/main/dashboard") {
      setCurrentPage("dashboard");
    } else if (window.location.pathname === "/main/newMonitor") {
      setCurrentPage("newMonitor");
    } else if (window.location.pathname === "/main/settings") {
      setCurrentPage("settings");
    }
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const loginToken = await localStorage.getItem("loginToken");
    const { data } = await axios.get(
      `https://api.sellercircle.co.uk/api/v1/users/current`,
      {
        headers: {
          Authorization: "Token " + loginToken,
          Accept: "application/json",
        },
      }
    );
    console.log(data, "data");
    setName(`${data.first_name || ""}`);
    setLoading(false);
  };

  return (
    <div className="dashboard_container">
      <div className="dashboardLeft_container">
        <h2>Trendy</h2>
        <Link
          to="/main/dashboard"
          onClick={() => setCurrentPage("dashboard")}
          className="dashboardButton_container"
        >
          <div
            className={`${
              currenctPage === "dashboard"
                ? "buttonLeft_cross"
                : "buttonLeftM_cross"
            } `}
          />
          <img src={dashboardImage} />
          <p>Dashboard</p>
        </Link>
        <div
          onClick={() => setIsOpen(true)}
          className="dashboardButton_container"
        >
          <div
            className={`${
              currenctPage === "newMonitor"
                ? "buttonLeft_cross"
                : "buttonLeftM_cross"
            } `}
          />
          <img src={plusImage} />
          <p>New Monitor</p>
        </div>
        <Link
          to="/main/settings"
          onClick={() => setCurrentPage("settings")}
          className="dashboardButton_container"
        >
          <div
            className={`${
              currenctPage === "settings"
                ? "buttonLeft_cross"
                : "buttonLeftM_cross"
            } `}
          />
          <img src={settingImage} />
          <p>Settings</p>
        </Link>
        <a href="https://trendy.gitbook.io/trendy-software/" className="dashboardButton_container" target="_blank">
          <div className="buttonLeft_cross" />
          <img src={guideImage} />
          <p>Guide</p>
        </a>
        <a href="https://discord.gg/s7JenbjqFK" className="dashboardButton_container" target="_blank">
          <div className="buttonLeft_cross" />
          <img src={discordImage} />
          <p>Join Discord</p>
        </a>
      </div>
      <div className="dashboardRight_container">
        <div className="dashboard_mainContainer">
          <div>
            <div className="dashboardName_container">
              <div className="nameTitle_container">
                {/* <img src={backImage}/> */}
                <div />
                <div className="dashboard_userName">
                  {isLoading ? <img src={Loader} /> : <h4>Hello {name}</h4>}
                  <div className="dashboard_avatar" >
                    <img
                        src="https://media.discordapp.net/attachments/897577265628602448/1128683265201549416/Social_Logo.png?width=686&height=686"
                        alt="Trendy Logo"
                        style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="hamburger__menu"
                onClick={() => setSideHamburgerToggle()}
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <>
              <Outlet />
            </>
          </div>
        </div>
      </div>

      <div
        className={`backdrop ${!sideToggle ? "showmenu" : "hiddenmenu"}`}
        onClick={() => setSideHamburgerToggle(false)}
      />
      <div
        className={`sidedrawer ${sideToggle ? "showmenu" : "hiddenmenu"}`}
        onClick={() => setSideHamburgerToggle(false)}
      >
        <div className="main__left__container__menu">
          <div className="left__container__buttons__menu">
            <div className="dashboardLeft_container_ham">
              <h2>Trendy</h2>
              <Link
                to="/main/dashboard"
                onClick={() => setCurrentPage("dashboard")}
                className="dashboardButton_container"
              >
                <div
                  className={`${
                    currenctPage === "dashboard"
                      ? "buttonLeft_cross"
                      : "buttonLeftM_cross"
                  } `}
                />
                <img src={dashboardImage} />
                <p>Dashboard</p>
              </Link>
              <div
                onClick={() => setIsOpen(true)}
                className="dashboardButton_container"
              >
                <div
                  className={`${
                    currenctPage === "newMonitor"
                      ? "buttonLeft_cross"
                      : "buttonLeftM_cross"
                  } `}
                />
                <img src={plusImage} />
                <p>New Monitor</p>
              </div>
              <Link
                to="/main/settings"
                onClick={() => setCurrentPage("settings")}
                className="dashboardButton_container"
              >
                <div
                  className={`${
                    currenctPage === "settings"
                      ? "buttonLeft_cross"
                      : "buttonLeftM_cross"
                  } `}
                />
                <img src={settingImage} />
                <p>Settings</p>
              </Link>
              <a href="https://trendy.gitbook.io/trendy-software/" className="dashboardButton_container" target="_blank">
                <div className="buttonLeft_cross" />
                <img src={guideImage} />
                <p>Guide</p>
              </a>
              <a href="https://discord.gg/s7JenbjqFK" className="dashboardButton_container" target="_blank">
                <div className="buttonLeft_cross" />
                <img src={discordImage} />
                <p>Join Discord</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <NewModal setIsOpen={setIsOpen} />}
    </div>
  );
};

export default Main;
