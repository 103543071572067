import React, { useEffect, useState } from "react";
import "../CSS/main.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Settings = (props) => {
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    onInitialize();
  }, []);
  const onInitialize = async () => {
    const loginToken = await localStorage.getItem("loginToken");
    if (!loginToken) {
      navigate("/");
    }
  };
  const onSave = async () => {
    if (!name) {
      window.alert("Please input the webhook name!");
      return false;
    }
    if (!url) {
      window.alert("Please input the webhook url!");
      return false;
    }
    const loginToken = await localStorage.getItem("loginToken");
    var valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    console.log(valid, "valid");
    if (!valid) {
      window.alert("Please input the valide webhook url");
      return false;
    }
    try {
      const res = await axios.get(
        `https://api.sellercircle.co.uk/api/v1/users/current`,
        {
          headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
          },
        }
      );
      console.log(res.data);
      const { data } = await axios.post(
        `https://api.sellercircle.co.uk/api/v1/users/${res?.data?.id}/hooks/add/`,
        {
          name,
          url,
        },
        {
          headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
          },
        }
      );
      console.log(data, "res");
      window.alert("Saved hook successfully");
    } catch (e) {
      console.log(e);
      window.alert(e.response.data);
    }
  };

  const onLogout = async () => {
    await localStorage.removeItem("loginToken");
    navigate("/");
  };
  return (
    <div className="dashboardChart_container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Settings</h4>
        <button onClick={onLogout}>Logout</button>
      </div>

      <div className="chart_title">
        <h5>Hook Name</h5>
      </div>
      <input
        placeholder="Enter Webhook Name..."
        onChange={(e) => setName(e.target.value)}
      />
      <div className="chart_title">
        <h5>Discord URL</h5>
      </div>
      <input
        placeholder="Enter Discord Webhook..."
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={onSave}>Save</button>
    </div>
  );
};

export default Settings;
