import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from "./components/signIn";
import SignUp from "./components/signup";
import ForgotPassword from "./components/forgotPassword";
import Main from "./components/main";
import Dashboard from "./components/dashboard";
import Settings from "./components/settings";
import ResetPassword from "./components/resetPassword";

const App = () => {
  return (
    <div>
      <Router>
        <main>
          <Routes>
            <Route exact path="/" element={<SignIn />} />
            <Route exact path="/signUp" element={<SignUp />} />
            <Route exact path="/forgotPassword" element={<ForgotPassword />} />
            <Route
              exact
              path="/rest-auth/password/reset/confirm/:id/:code"
              element={<ResetPassword />}
            />
            <Route exact path="main" element={<Main />}>
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="settings" element={<Settings />} />
            </Route>
          </Routes>
        </main>
      </Router>
    </div>
  );
};

export default App;
